const KEYWORD = {
  // 순발력
  QUICKNESS: { key: 'QUICKNESS', value: '순발력' },
  // 센스
  SENSIBLE: { key: 'SENSIBLE', value: '센스' },
  // 창의력
  CREATIVE: { key: 'CREATIVE', value: '창의력' },
  // 협력
  COOPERATIVE: { key: 'COOPERATIVE', value: '협력' },
  // 액티브
  ACTIVE: { key: 'ACTIVE', value: '액티브' },
  // 두뇌
  BRAIN: { key: 'BRAIN', value: '두뇌' },
  // 심리
  PSYCHOLOGICAL: { key: 'PSYCHOLOGICAL', value: '심리' },
  // 행운
  LUCK: { key: 'LUCK', value: '행운' },
  // 상식
  COMMON_SENSE: { key: 'COMMON_SENSE', value: '상식' },
  // 준비물
  PREPARATION: { key: 'PREPARATION', value: '준비물' },
};

Object.freeze(KEYWORD);

export default KEYWORD;
