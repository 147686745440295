const AGE = {
  UNDER_TEENAGER: { key: 'UNDER_TEENAGER', value: '10대 미만' },
  TEENAGER: { key: 'TEENAGER', value: '10대' },
  TWENTIES: { key: 'TWENTIES', value: '20대' },
  THIRTIES: { key: 'THIRTIES', value: '30대' },
  FORTIES: { key: 'FORTIES', value: '40대' },
  OVER_FIFTIES: { key: 'OVER_FIFTIES', value: '50대 이상' },
};

Object.freeze(AGE);

export default AGE;
