const PURPOSE = {
  WORKSHOP: { key: 'WORKSHOP', value: '워크샵' },
  SPORTS_DAY: { key: 'SPORTS_DAY', value: '체육대회' },
  MT: { key: 'MT', value: '대학생 MT' },
  GATHERING: { key: 'GATHERING', value: '모임' },
  RETREAT: { key: 'RETREAT', value: '수련회/수학여행' },
};

Object.freeze(PURPOSE);

export default PURPOSE;
